import React, { FC } from 'react';
import { Layout, CategoryIcons, ArticleContent } from 'components';
import { graphql, Link } from 'gatsby';
import { truncate } from 'utils';
import moment from 'moment';
import Img from 'gatsby-image';
import { Count } from 'components';
import { normalizePath } from 'utils/get-url-path';
import UserIcon from 'assets/svg/usericon.inline.svg';
import ChatIcon from 'assets/svg/chat.inline.svg';
import RightIcon from 'assets/svg/rightarrow.inline.svg';
import { TagQuery } from 'queries/tags';

const tagPage: FC = ({ data, pageContext }: any) => {
  const pagedata = data.AllTagPage.edges;
  const tagdata = TagQuery();
  const taglist = tagdata?.allWpTag?.edges;
  const limit = 10;
  return (
    <Layout>
      <div className="pill-tags">
        <ul className="pill-tags-list">
          {taglist?.map((tags: any, index: number) => {
            if (tags.node.count > 0) {
              return index < limit ? (
                <Link
                  to={normalizePath(tags.node.uri)}
                  className={`pill-tags-links ${
                    tags.node.name === pageContext.name ? 'selected-tag' : ''
                  }`}
                >
                  <li>{tags.node.name}</li>
                </Link>
              ) : null;
            }
            return null;
          })}
        </ul>
      </div>
      {pagedata.map((item: any) => (
        <div className="main-page" key={item.node.name}>
          <h1 className="page-title">{item.node.name}</h1>
          <p className="page-description">{item.node.description}</p>
          <div className="articles-wrapper">
            {pagedata[0].node.posts.nodes.map((post: any) => {
              <CategoryIcons IconsData={post} />;
              const { content } = post;
              const trimcontent = truncate(content, 300);
              const articletitle = post.title;
              const trimtitle = truncate(articletitle, 45);
              return (
                <div className="articles-item" key={post.title}>
                  <div className="articles-image">
                    {post?.featuredImage && (
                      <Img
                        fluid={
                          post.featuredImage.node.localFile
                            ?.childrenImageSharp[0].fluid
                        }
                      />
                    )}
                  </div>
                  <div className="articles-content">
                    <div className="articles-content-item-description">
                      <div className="articles-content-button">
                        <Link to={normalizePath(post.uri)}>
                          <h5 className="articles-title">{trimtitle}</h5>
                        </Link>
                        <div className="articles-details">
                          <span>
                            <UserIcon /> {post.author.node.name}
                          </span>
                          <span className="articles-list-items">
                            {moment(post.date).format('MMMM D, YYYY')}
                          </span>
                          <span className="articles-list-items">
                            <Count articleUrl={post.uri} />
                          </span>
                        </div>
                        {!!post.content && (
                          <ArticleContent ArticleSummary={trimcontent} />
                        )}
                      </div>
                      <div className="articles-category">
                        <Link to={normalizePath(post.uri)}>
                          <span className="articles-link">Read More</span>{' '}
                          <RightIcon />
                        </Link>
                        <div className="articles-category-value">
                          <Link
                            to={normalizePath(post.categories.nodes[0].uri)}
                          >
                            <span>{post.categories.nodes[0].name}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </Layout>
  );
};

export default tagPage;

export const query = graphql`
  query ($name: String) {
    AllTagPage: allWpTag(filter: { name: { eq: $name } }) {
      edges {
        node {
          name
          description
          slug
          posts {
            nodes {
              categories {
                nodes {
                  name
                  uri
                }
              }
              date
              commentCount
              title
              content
              uri
              author {
                node {
                  name
                }
              }
              featuredImage {
                node {
                  localFile {
                    childrenImageSharp {
                      fluid(maxWidth: 340, maxHeight: 180) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
